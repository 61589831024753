"use client"

import React, { useState, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FilterListIcon from "@mui/icons-material/FilterList"
import BarChartIcon from "@mui/icons-material/BarChart"
import TableChartIcon from "@mui/icons-material/TableChart"
import Agent from "../../../api/agent"
import { Countries } from "../../../Util/Util"
import { INTAKES, intakeYearList, STUDENTSOURCES, months, ReportStatusTypes } from "../../../Util/Constants"
import ErrorPopup from "../RegionalReport/ErrorPopUp"
import ComparisonGraph from "./ComparisonGraph"
import { Link } from "react-router-dom"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <CircularProgress />
  </div>
)

const GlobalSalesProgressReport = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [branches, setBranches] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [citizenship, setCitizenship] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)
  const [showFilters, setShowFilters] = useState(true)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [type, setType] = useState(1)
  const [viewMode, setViewMode] = useState("both")
  const [monthlyTargets, setMonthlyTargets] = useState([])
  const [monthlyForecasts, setMonthlyForecasts] = useState([])
  const [selectedMetrics, setSelectedMetrics] = useState([{ key: "organicLead", type: "Organic Lead" }])
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [chartType, setChartType] = useState("bar")
  const [selectedYear, setSelectedYear] = useState("")
  const [selectedSources, setSelectedSources] = useState([])
  const [selectedYears, setSelectedYears] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedMetric, setSelectedMetric] = useState("organicLead")
  const [initialLoad, setInitialLoad] = useState(true)

  const hasAccess = permissions.CanAccessGlobalSalesReportB2C

  useEffect(() => {
    if (hasAccess) {
      fetchBranches()
      fetchTargetsAndForecasts()
    }
  }, [hasAccess])

  const fetchBranches = async () => {
    setLoadingBranches(true)
    try {
      const response = await Agent.Cache.getBranchOffices()
      if (Array.isArray(response)) {
        setBranches(
          response.map((branch) => ({
            id: branch.id.toString(),
            label: branch.name,
          })),
        )
      } else {
        console.error("Unexpected response format for branch offices:", response)
      }
    } catch (err) {
      console.error("Error fetching branch offices:", err)
      setError("Failed to fetch branch offices. Please try again later.")
    } finally {
      setLoadingBranches(false)
    }
  }

  const fetchTargetsAndForecasts = async () => {
    try {
      const targetsResponse = await Agent.Report.globalSalesTarget()
      const forecastsResponse = await Agent.Report.globalSalesForecast()

      setMonthlyTargets(targetsResponse)
      setMonthlyForecasts(forecastsResponse)
    } catch (err) {
      console.error("Error fetching targets and forecasts:", err)
    } finally {
      setInitialLoad(false)
    }
  }

  const fetchData = useCallback(async () => {
    if (!hasAccess) return

    setLoading(true)
    setError(null)
    try {
      const params = {
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        branches: selectedBranches.map((branch) => Number.parseInt(branch.id, 10)),
        citizenship,
        type,
        sources: selectedSources.map((source) => source.id.toString()),
        year: selectedYear ? [selectedYear] : [],
      }

      const [progressResponse, targetResponse, forecastResponse] = await Promise.all([
        Agent.Report.globalSalesProgress({ ...params, year: params.year }),
        Agent.Report.globalSalesTarget({ ...params, year: params.year }),
        Agent.Report.globalSalesForecast({ ...params, year: params.year }),
      ])

      if (Array.isArray(progressResponse) && progressResponse.length > 0) {
        setData(progressResponse)
      } else {
        setError("No data available for the selected criteria.")
      }

      if (Array.isArray(targetResponse)) {
        setMonthlyTargets(targetResponse)
      }

      if (Array.isArray(forecastResponse)) {
        setMonthlyForecasts(forecastResponse)
      }
      setFiltersChanged(false)
    } catch (err) {
      setError(err.message || "Failed to fetch data. Please try again.")
      setShowErrorPopup(true)
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [
    hasAccess,
    intakeYears,
    intakeMonths,
    startDate,
    endDate,
    selectedBranches,
    citizenship,
    type,
    selectedSources,
    selectedYear,
  ])

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false)
    setError(null)
  }

  const handleGenerateReport = () => {
    fetchData()
  }

  const handleResetFilters = () => {
    setIntakeYears([])
    setIntakeMonths([])
    setStartDate("")
    setEndDate("")
    setSelectedBranches([])
    setSelectedYear("")
    setCitizenship([])
    setData([])
    setError(null)
    setShowErrorPopup(false)
    setType(1)
    setSelectedMetrics([{ key: "organicLead", type: "Organic Lead" }])
    setFiltersChanged(false)
    setSelectedYears([])
    setSelectedSources([])
  }

  const handleFilterChange = () => {
    setFiltersChanged(true)
  }

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode)
    }
  }

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType)
  }

  const handleYearChange = (newYear) => {
    setSelectedYear(newYear)
    fetchData({ year: [newYear.toString()] })
  }

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === 0 || value === "") return ""
    if (isRatio) {
      const formattedValue = Number(value).toFixed(2)
      return formattedValue === "0.00" ? "" : `${formattedValue}%`
    }
    return value.toString()
  }

  const calculateTotal = (statusKey) => {
    return data.reduce((sum, monthData) => {
      const value = monthData[statusKey]
      return sum + (value !== undefined && value !== null ? Number(value) : 0)
    }, 0)
  }

  if (!hasAccess) {
    return (
      <Paper elevation={3} className="p-6 bg-gray-50">
        <ErrorPopup error="You do not have permission to access the Global Sales Progress Report(B2C)!" />
      </Paper>
    )
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="w-full mx-auto px-2 sm:px-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
            Global Sales Progress Report ({type === 1 ? "Overall" : type === 2 ? "B2C" : "B2B"})
          </h1>
          <Link to="/reportpanel" className="flex items-center text-blue-600 hover:text-blue-800">
            <ArrowBackIcon className="h-5 w-5 mr-1" />
            Back to Reporting Panel
          </Link>
        </div>
        <div className="bg-white rounded-lg shadow-md p-2 md:p-4">
          <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
            <div className="flex items-center space-x-4 mb-4 md:mb-0">
              <FormControl size="small" className="min-w-[150px]">
                <InputLabel id="type-select-label">Report Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={type}
                  label="Report Type"
                  onChange={(e) => {
                    setType(e.target.value)
                    handleFilterChange()
                  }}
                >
                  <MenuItem value={1}>Overall</MenuItem>
                  <MenuItem value={2}>B2C</MenuItem>
                  <MenuItem value={3}>B2B</MenuItem>
                </Select>
              </FormControl>
              <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewModeChange} aria-label="view mode">
                <ToggleButton value="chart" aria-label="chart view">
                  <BarChartIcon />
                </ToggleButton>
                <ToggleButton value="table" aria-label="table view">
                  <TableChartIcon />
                </ToggleButton>
                <ToggleButton value="both" aria-label="both views">
                  <BarChartIcon />
                  <TableChartIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FilterListIcon className="w-5 h-5 mr-2" />
              {showFilters ? "Hide Filters" : "Show Filters"}
            </button>
          </div>
          {showFilters && (
            <div className="mb-6">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                      handleFilterChange()
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                      handleFilterChange()
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-years-label">Intake Years</InputLabel>
                    <Select
                      labelId="intake-years-label"
                      id="intake-years"
                      multiple
                      value={intakeYears}
                      label="Intake Years"
                      onChange={(e) => {
                        setIntakeYears(e.target.value)
                        handleFilterChange()
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {intakeYearList.map((year) => (
                        <MenuItem key={year.year} value={year.year.toString()}>
                          <Checkbox checked={intakeYears.indexOf(year.year.toString()) > -1} />
                          <ListItemText primary={year.year} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-month-label">Intake Months</InputLabel>
                    <Select
                      labelId="intake-month-label"
                      id="intake-month"
                      multiple
                      value={intakeMonths}
                      label="Intake Months"
                      onChange={(e) => {
                        setIntakeMonths(e.target.value)
                        handleFilterChange()
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={INTAKES.find((intake) => intake.id.toString() === value)?.type}
                              size="small"
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {INTAKES.map((intake) => (
                        <MenuItem key={intake.id} value={intake.id.toString()}>
                          <Checkbox checked={intakeMonths.indexOf(intake.id.toString()) > -1} />
                          <ListItemText primary={intake.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    multiple
                    id="citizenship"
                    options={Countries.map((country) => ({
                      id: country.id.toString(),
                      label: country.name,
                    }))}
                    value={citizenship.map((id) => ({
                      id,
                      label: Countries.find((c) => c.id.toString() === id)?.name || id,
                    }))}
                    onChange={(_, newValue) => {
                      setCitizenship(newValue.map((v) => v.id))
                      handleFilterChange()
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Citizenship" placeholder="Select citizenship" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.label}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="year-filter-label">Year</InputLabel>
                    <Select
                      labelId="year-filter-label"
                      id="year-filter"
                      value={selectedYear}
                      label="Year"
                      onChange={(e) => {
                        setSelectedYear(e.target.value)
                        handleFilterChange()
                      }}
                    >
                      {/* <MenuItem value="">All</MenuItem> */}
                      {intakeYearList.map((year) => (
                        <MenuItem key={year.year} value={year.year.toString()}>
                          {year.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    id="source-filter"
                    options={STUDENTSOURCES}
                    getOptionLabel={(option) => option.type}
                    value={selectedSources}
                    onChange={(_, newValue) => {
                      setSelectedSources(newValue)
                      handleFilterChange()
                    }}
                    renderInput={(params) => <TextField {...params} label="Sources" placeholder="Select sources" />}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={index} label={option.type} size="small" {...getTagProps({ index })} />
                      ))
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    id="branches"
                    options={branches}
                    getOptionLabel={(option) => option.label}
                    value={selectedBranches}
                    onChange={(_, newValue) => {
                      setSelectedBranches(newValue)
                      handleFilterChange()
                    }}
                    renderInput={(params) => <TextField {...params} label="Branches" placeholder="Select branches" />}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={index} label={option.label} size="small" {...getTagProps({ index })} />
                      ))
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={handleResetFilters}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Reset Filters
                </button>
                <button
                  onClick={handleGenerateReport}
                  disabled={loading || !filtersChanged}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                    loading || !filtersChanged ? "bg-gray-300 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
                  }`}
                >
                  {loading ? "Generating..." : "Generate Report"}
                </button>
              </div>
            </div>
          )}

          {error && (
            <div
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-md shadow-md"
              role="alert"
            >
              <div className="flex items-center">
                <div className="py-1">
                  <svg
                    className="fill-current h-6 w-6 text-red-500 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold">Error</p>
                  <p className="text-sm">{error}</p>
                </div>
              </div>
            </div>
          )}

          {loading ? (
            <Paper elevation={3} className="p-4">
              <Typography variant="body1" className="mb-2 text-center">
                Generating report...
              </Typography>
              <LoadingAnimation />
            </Paper>
          ) : (
            !error &&
            data.length > 0 && (
              <div className="space-y-8">
                {(viewMode === "chart" || viewMode === "both") && (
                  <Paper elevation={3} className="p-4">
                    <div className="h-[500px]">
                      <ComparisonGraph
                        data={data}
                        statusTypes={ReportStatusTypes}
                        months={months}
                        monthlyTargets={monthlyTargets}
                        monthlyForecasts={monthlyForecasts}
                        selectedMetric={selectedMetric}
                        onMetricChange={setSelectedMetric}
                        chartType={chartType}
                        onChartTypeChange={handleChartTypeChange}
                      />
                    </div>
                  </Paper>
                )}
                {(viewMode === "table" || viewMode === "both") && (
                  <Paper elevation={3} className="overflow-x-auto max-w-full rounded-lg">
                    <div className="overflow-x-auto">
                      <table className="w-full text-xs border-collapse">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40"
                              rowSpan={2}
                            >
                              Status Type
                            </th>
                            {months.map((month) => (
                              <th
                                key={month}
                                className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                                colSpan={3}
                              >
                                {month.slice(0, 3)}
                              </th>
                            ))}
                            <th
                              className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-green-200"
                              colSpan={3}
                            >
                              Total
                            </th>
                          </tr>
                          <tr>
                            {[...months, "Total"].map((month) => (
                              <React.Fragment key={`${month}-details`}>
                                <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                                  Target
                                </th>
                                <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                                  Achieve
                                </th>
                                <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-blue-100">
                                  Forecast
                                </th>
                              </React.Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {ReportStatusTypes.map((status) => (
                            <tr key={status.id} className="hover:bg-sky-50">
                              <td className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 bg-white border-r border-gray-300">
                                {status.type}
                              </td>
                              {months.map((_, monthIndex) => {
                                const monthData = data.find((d) => d.month === monthIndex + 1) || {}
                                const targetData = monthlyTargets.find((t) => t.month === monthIndex + 1) || {}
                                const forecastData = monthlyForecasts.find((f) => f.month === monthIndex + 1) || {}
                                return (
                                  <React.Fragment key={monthIndex}>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-red-600 text-xs">
                                        {formatValue(targetData[status.key], status.isRatio)}
                                      </div>
                                    </td>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-green-600 text-xs">
                                        {formatValue(monthData[status.key], status.isRatio)}
                                      </div>
                                    </td>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-blue-600 text-xs">
                                        {formatValue(forecastData[status.key], status.isRatio)}
                                      </div>
                                    </td>
                                  </React.Fragment>
                                )
                              })}
                              <td className="py-1 px-1 text-center border-r border-gray-300">
                                <div className="font-medium text-red-600 text-xs">
                                  {formatValue(
                                    monthlyTargets.reduce((sum, month) => sum + (month[status.key] || 0), 0),
                                    status.isRatio,
                                  )}
                                </div>
                              </td>
                              <td className="py-1 px-1 text-center border-r border-gray-300">
                                <div className="font-medium text-green-600 text-xs">
                                  {formatValue(calculateTotal(status.key), status.isRatio)}
                                </div>
                              </td>
                              <td className="py-1 px-1 text-center border-r border-gray-300">
                                <div className="font-medium text-blue-600 text-xs">
                                  {formatValue(
                                    monthlyForecasts.reduce((sum, month) => sum + (month[status.key] || 0), 0),
                                    status.isRatio,
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Paper>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
})

export default connect(mapStateToProps)(GlobalSalesProgressReport)

