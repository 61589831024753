/* eslint-disable import/namespace */
import React from "react"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Typography, Chip, Box } from "@mui/material"

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ data, statusTypes, selectedUser }) => {
  const getStatusColor = (status) => {
    const type = status.type.toLowerCase()
    const key = status.key.toLowerCase()

    switch (type) {
      case "enrolled":
        return "rgba(46, 204, 113, 0.8)" // Emerald
      case "enroll. ratio":
        return "rgba(39, 174, 96, 0.8)" // Nephritis
      case "deposit":
        return "rgba(40, 180, 99, 0.8)" // Green Sea
      case "num of cas":
        return "rgba(34, 153, 84, 0.8)" // Nephritis (darker)
      case "cond. offer":
      case "uncond. offer":
        return "rgba(52, 152, 219, 0.8)" // Peter River (sky blue)
      case "total offer":
        return "rgba(41, 128, 185, 0.8)" // Belize Hole (darker sky blue)
      case "offer ratio":
        return "rgba(93, 173, 226, 0.8)" // Light Sky Blue
      case "organic lead":
      case "paid lead":
      case "total lead":
      case "profile ready":
        return "rgba(173, 216, 230, 0.8)" // Light Blue
      case "applied":
      case "apply ratio":
        return "rgba(241, 196, 15, 0.8)" // Sun Flower (yellow)
      case "rejection":
      case "reject. ratio":
        return "rgba(231, 76, 60, 0.8)" // Pomegranate (red)
      default:
        return "rgba(149, 165, 166, 0.8)" // Gray
    }
  }

  const filteredData = selectedUser ? data.filter((counselor) => counselor.userId.toString() === selectedUser.id) : data

  const chartData = {
    labels: statusTypes.map((status) => status.type),
    datasets: filteredData.map((counselor) => ({
      label: counselor.userName,
      data: statusTypes.map((status) => counselor[status.key] || 0),
      backgroundColor: statusTypes.map((status) => getStatusColor(status)),
      borderColor: statusTypes.map((status) => getStatusColor(status).replace("0.8", "1")),
      borderWidth: 1,
    })),
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        align: "center",
        labels: {
          boxWidth: 15,
          padding: 15,
          font: {
            size: 12,
          },
          generateLabels: (chart) => {
            return chart.data.labels.map((label, i) => ({
              text: label,
              fillStyle: getStatusColor(statusTypes[i]),
              strokeStyle: getStatusColor(statusTypes[i]).replace("0.8", "1"),
              lineWidth: 1,
              hidden: false,
              index: i,
            }))
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || ""
            const statusLabel = context.label || ""
            const value = context.parsed || 0
            return `${label} - ${statusLabel}: ${value}`
          },
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 120, 
      },
    },
  }

  return (
    <div className="flex flex-col justify-center items-center w-full max-w-4xl mx-auto mt-8 bg-white rounded-lg shadow-lg p-6">
      <Typography variant="h6" className="mb-4 text-gray-800">
        {selectedUser ? `${selectedUser.label}'s Performance` : "Counselor Performance Comparison"}
      </Typography>
      {!selectedUser && (
        <Box className="flex flex-wrap justify-center gap-2 mb-4">
          {filteredData.map((counselor) => (
            <Chip
              key={counselor.userId}
              label={counselor.userName}
              color="primary"
              variant="outlined"
              className="text-sm"
            />
          ))}
        </Box>
      )}
      <div className="w-full h-[500px]">
        <Pie data={chartData} options={options} />
      </div>
    </div>
  )
}

export default PieChart

