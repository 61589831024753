/* eslint-disable import/namespace */
import React,{ useState, useMemo } from "react"
import { Bar, Line, Pie } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

import { Card, CardContent, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend)

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const RegionalComparisonGraph = ({ data, statusTypes, monthlyTargets, monthlyForecasts }) => {
  const [selectedMetric, setSelectedMetric] = useState(statusTypes[0])
  const [chartType, setChartType] = useState("bar")

  const colors = {
    target: "rgba(239, 68, 68, 0.8)", 
    achievement: "rgba(16, 185, 129, 0.8)", 
    forecast: "rgba(59, 130, 246, 0.8)",
  }

  const chartData = useMemo(
    () => ({
      labels: months,
      datasets: [
        {
          label: `Target`,
          data: months.map((_, index) => {
            const monthData = monthlyTargets.find((item) => item.month === index + 1)
            return monthData ? monthData[selectedMetric.key] : 0
          }),
          backgroundColor: colors.target,
          borderColor: colors.target,
          borderWidth: 2,
          tension: 0.1,
        },
        {
          label: `Achievement`,
          data: months.map((_, index) => {
            const monthData = data.find((item) => item.month === index + 1)
            return monthData ? monthData[selectedMetric.key] : 0
          }),
          backgroundColor: colors.achievement,
          borderColor: colors.achievement,
          borderWidth: 2,
          tension: 0.1,
        },
        {
          label: `Forecast`,
          data: months.map((_, index) => {
            const monthData = monthlyForecasts.find((item) => item.month === index + 1)
            return monthData ? monthData[selectedMetric.key] : 0
          }),
          backgroundColor: colors.forecast,
          borderColor: colors.forecast,
          borderWidth: 2,
          tension: 0.1,
        },
      ],
    }),
    [data, monthlyTargets, monthlyForecasts, selectedMetric],
  )

  const pieData = useMemo(
    () => ({
      labels: ["Target", "Achievement", "Forecast"],
      datasets: [
        {
          data: [
            monthlyTargets.reduce((sum, item) => sum + (item[selectedMetric.key] || 0), 0),
            data.reduce((sum, item) => sum + (item[selectedMetric.key] || 0), 0),
            monthlyForecasts.reduce((sum, item) => sum + (item[selectedMetric.key] || 0), 0),
          ],
          backgroundColor: [colors.target, colors.achievement, colors.forecast],
          borderWidth: 0,
        },
      ],
    }),
    [data, monthlyTargets, monthlyForecasts, selectedMetric],
  )

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            size: 14,
            weight: "bold",
          },
          padding: 20,
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        titleColor: "#111827",
        bodyColor: "#374151",
        borderColor: "#D1D5DB",
        borderWidth: 1,
        padding: 12,
        bodyFont: {
          size: 14,
        },
        titleFont: {
          size: 16,
          weight: "bold",
        },
      },
    },
    scales:
      chartType !== "pie"
        ? {
            y: {
              beginAtZero: true,
              ticks: {
                font: {
                  size: 12,
                  weight: "bold",
                },
                color: "#4B5563",
              },
              grid: {
                color: "#E5E7EB",
                drawBorder: false,
              },
            },
            x: {
              ticks: {
                font: {
                  size: 12,
                  weight: "bold",
                },
                color: "#4B5563",
              },
              grid: {
                display: false,
                drawBorder: false,
              },
            },
          }
        : {},
  }

  const calculateTrend = (metric) => {
    const totalAchievement = data.reduce((sum, item) => sum + (item[metric.key] || 0), 0)
    const totalTarget = monthlyTargets.reduce((sum, item) => sum + (item[metric.key] || 0), 0)
    const percentageDifference = totalTarget !== 0 ? ((totalAchievement - totalTarget) / totalTarget) * 100 : 0
    return { percentageDifference, trendingUp: percentageDifference >= 0 }
  }

  const handleMetricChange = (event) => {
    const newMetric = statusTypes.find((m) => m.key === event.target.value)
    setSelectedMetric(newMetric)
  }

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value)
  }

  const trend = calculateTrend(selectedMetric)

  const totalTarget = monthlyTargets.reduce((sum, item) => sum + (item[selectedMetric.key] || 0), 0)
  const totalAchievement = data.reduce((sum, item) => sum + (item[selectedMetric.key] || 0), 0)
  const totalForecast = monthlyForecasts.reduce((sum, item) => sum + (item[selectedMetric.key] || 0), 0)

  return (
    <Card className="w-full mb-8 bg-white shadow-lg rounded-lg overflow-hidden">
      <CardContent className="p-8">
        <Grid container spacing={4} alignItems="center" className="mb-8">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className="font-bold text-gray-900 mb-2">
              {selectedMetric.type} Performance
            </Typography>
            <Typography variant="subtitle1" className="text-gray-600">
              Year-to-Date Comparison
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="flex justify-end items-center space-x-4">
              <FormControl size="small" className="min-w-[140px]">
                <InputLabel id="metric-select-label">Metric</InputLabel>
                <Select
                  labelId="metric-select-label"
                  id="metric-select"
                  value={selectedMetric.key}
                  label="Metric"
                  onChange={handleMetricChange}
                  className="bg-white text-sm"
                >
                  {statusTypes.map((status) => (
                    <MenuItem key={status.id} value={status.key}>
                      {status.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" className="min-w-[140px]">
                <InputLabel id="chart-type-label">Chart Type</InputLabel>
                <Select
                  labelId="chart-type-label"
                  id="chart-type-select"
                  value={chartType}
                  label="Chart Type"
                  onChange={handleChartTypeChange}
                  className="bg-white text-sm"
                >
                  <MenuItem value="bar">Bar</MenuItem>
                  <MenuItem value="line">Line</MenuItem>
                  <MenuItem value="pie">Pie</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Box className="mb-8 p-6 bg-gray-50 rounded-lg flex flex-col md:flex-row items-center justify-between shadow-sm">
          <Box className="flex items-center space-x-4 mb-4 md:mb-0">
            <Typography variant="h6" component="div" className="flex items-center space-x-3">
              <span className="text-gray-700 font-semibold">Performance:</span>
              <span className={`font-bold text-lg ${trend.trendingUp ? "text-green-600" : "text-red-600"}`}>
                {trend.trendingUp ? (
                  <ArrowUpwardIcon className="text-green-600" />
                ) : (
                  <ArrowDownwardIcon className="text-red-600" />
                )}
                {Math.abs(trend.percentageDifference).toFixed(1)}%
              </span>
            </Typography>
          </Box>
          <Box className="flex items-center space-x-6">
            <Box className="flex flex-col items-center">
              <Typography variant="subtitle2" className="text-gray-600">
                Total Target
              </Typography>
              <Typography variant="h6" className="font-bold text-red-600">
                {totalTarget.toLocaleString()}
              </Typography>
            </Box>
            <Box className="flex flex-col items-center">
              <Typography variant="subtitle2" className="text-gray-600">
                Total Achievement
              </Typography>
              <Typography variant="h6" className="font-bold text-green-600">
                {totalAchievement.toLocaleString()}
              </Typography>
            </Box>
            <Box className="flex flex-col items-center">
              <Typography variant="subtitle2" className="text-gray-600">
                Total Forecast
              </Typography>
              <Typography variant="h6" className="font-bold text-blue-600">
                {totalForecast.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="h-[500px] w-full mb-12 p-4 bg-white rounded-lg shadow-sm">
          {chartType === "bar" && <Bar data={chartData} options={options} />}
          {chartType === "line" && (
            <Line data={chartData} options={{ ...options, elements: { point: { radius: 4 } } }} />
          )}
          {chartType === "pie" && <Pie data={pieData} options={options} />}
        </Box>

        <Box className="overflow-x-auto bg-white rounded-lg shadow-sm">
          <table className="w-full text-sm border-collapse">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider border-b">
                  Month
                </th>
                <th className="px-6 py-3 text-left text-xs font-semibold text-red-600 uppercase tracking-wider border-b">
                  Target
                </th>
                <th className="px-6 py-3 text-left text-xs font-semibold text-green-600 uppercase tracking-wider border-b">
                  Achievement
                </th>
                <th className="px-6 py-3 text-left text-xs font-semibold text-blue-600 uppercase tracking-wider border-b">
                  Forecast
                </th>
              </tr>
            </thead>
            <tbody>
              {months.map((month, index) => (
                <tr key={month} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-b">{month}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-red-600 font-semibold border-b">
                    {chartData.datasets[0].data[index].toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600 font-semibold border-b">
                    {chartData.datasets[1].data[index].toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 font-semibold border-b">
                    {chartData.datasets[2].data[index].toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </CardContent>
    </Card>
  )
}

export default RegionalComparisonGraph

