import React, { useState, useEffect, useContext, useCallback } from "react"
import { Button, IconButton, Typography, Grid, Snackbar, Toolbar } from "@mui/material"
import MuiAlert from "@mui/material/Alert"
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPagination,
  GridToolbar,
} from "@mui/x-data-grid-pro"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import * as actions from "../../store/actions/index"
import {
  STATUS_APPROVED,
  MUI_LICENSE_KEY,
  STUDENTSTATUS,
  STUDENTSOURCES,
  LEADORIGINS,
  LEADCHANNELS,
  StudentLead_Activities,
  COMPLIANCE_STATUS,
  STUDY_LEVEL_GROUPS,
  GENDER,
} from "../../Util/Constants"
import { Countries } from "../../Util/Util"
import UserContext from "../../context/user-context"
import Agent from "../../api/agent"
import StudentNotes from "./StudentNotes/StudentNotes"
import CustomToolbar from "./CustomToolBar"
import EditIcon from "@mui/icons-material/Edit"
import Description from "@mui/icons-material/Description"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { NotInterested, Add as AddIcon } from "@mui/icons-material"
import MyTooltip from "../Common/Tooltip/MyTooltip"
import { addMonths } from "date-fns"
import { LicenseInfo } from "@mui/x-data-grid-pro"
import StudentFilters from "./StudentFilters"
import { StudentAssignment } from "../Common/Widgets/StudentAssignment"

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const StudentList = ({
  users,
  role,
  permissions,
  loadUsers,
  agents,
  loadAgents,
  isInternal,
  agentId = 0,
  referralId = 0,
  userName,
}) => {
  const navigate = useNavigate()
  const userContext = useContext(UserContext)
  const [filters, setFilters] = useState({
    id: "",
    name: "",
    email: "",
    PhoneNumber: "",
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
    dateType: 1,
    branchOfficeId: 0,
    Status: 0,
    LeadActivity: 0,
    PartnerReferralId: 0,
    PartnerAgentId: 0,
    ComplianceStatus: 0,
    TeamLeader: 0,
    eventId: 0,
    AssessmentTeamId: 0,
    FollowupTeamId: 0,
    Source: 0,
    Channel: 0,
    PassportNumber: "",
    Origin: 0,
    StudentType: 0,
    CountryOfResidence: 0,
    PreferenceStudyLevelId: "",
    dateOfBirth: null,
    destination: 0,
  })

  const [tempFilters, setTempFilters] = useState(filters)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(800)
  const [totalPages, setTotalPages] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [selectionModel, setSelectionModel] = useState([])
  const [bulkUpdate, setBulkUpdate] = useState(false)
  const [openAddNote, setOpenAddNote] = useState(false)
  const [studentId, setStudentId] = useState("")
  const [showFilters, setShowFilters] = useState(true)
  const [student, setStudent] = useState(null)
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState([])
  const [referralList, setReferralList] = useState([])
  const [AgentList, setAgentList] = useState([])
  const [filteredTeamLeaders, setFilteredTeamLeaders] = useState([])
  const [filteredFollowAndAssessmentTeams, setFilteredFollowAndAssessmentTeams] = useState([])
  const [offices, setOffices] = useState([])
  const [events, setEventList] = useState([])
  const [successMessage, setSuccessMessage] = useState("")
  const [updatedStudent, setUpdatedStudent] = useState(null)

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices)
    Agent.Referral.List().then(setReferralList)
    Agent.Agents.list().then(setAgentList)
    Agent.Events.getEventList().then((res) => {
      res.sort((a, b) => b.id - a.id)
      setEventList(res)
    })
  }, [])

  useEffect(() => {
    const filteredTeamLeaders = users.filter(
      (user) =>
        !user.isDisabled &&
        (user.role === "ApplicationTeamLeader" ||
          user.role === "SuperAdmin" ||
          user.role === "SeniorAdmin" ||
          user.role === "Admin"),
    )
    const filteredFollowAndAssessmentTeams = users.filter((user) => !user.isDisabled && [1, 2, 3].includes(user.roleId))

    setFilteredTeamLeaders(filteredTeamLeaders)
    setFilteredFollowAndAssessmentTeams(filteredFollowAndAssessmentTeams)
  }, [users])

  useEffect(() => {
    if (isInternal) {
      if (users.length === 0) {
        loadUsers()
          .then(() => getData(filters))
          .catch((error) => console.log(error))
      } else {
        getData(filters)
      }
    } else {
      getData(filters)
    }
  }, [users, pageNumber])

  const getParams = useCallback(
    (currentFilters) => {
      const params = new URLSearchParams()
      params.append("pageNumber", pageNumber.toString())
      params.append("pageSize", pageSize.toString())

      Object.entries(currentFilters).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
          if (key === "startDate" || key === "endDate" || key === "dateOfBirth") {
            params.append(key, new Date(value).toDateString())
          } else if (typeof value === "number") {
            params.append(key, value.toString())
          } else {
            params.append(key, value)
          }
        }
      })

      if (agentId > 0) {
        params.append("agentId", agentId.toString())
      }
      if (referralId > 0) {
        params.append("ReferralId", referralId.toString())
      }

      return params
    },
    [pageNumber, pageSize, agentId, referralId],
  )


  console.log(student, "source")
  const getRows = useCallback(
    (rows) => {
      return rows?.map((student) => ({
        id: student.id,
        name: student.firstName + " " + student.lastName,
        email: student.email,
        citizenship:
          student.citizenship && student.citizenship > 0
            ? Countries.find(({ id }) => id === student.citizenship)?.name
            : "",
        residence:
          student.countryOfResidence && student.countryOfResidence > 0
            ? Countries.find(({ id }) => id === student.countryOfResidence)?.name
            : "",
        applicationCount: student.noOfApplications == 0 ? "" : student.noOfApplications,
        appUserDetailsId: student.appUserDetailsId,
        assessmentTeamId: student.assessmentTeamId,
        complianceStatus: student.complianceStatus || null,
        complianceStatusName:
          student.complianceStatus && student.complianceStatus > 0
            ? COMPLIANCE_STATUS.find(({ id }) => id === student.complianceStatus)?.type
            : null,
        assessmentTeam:
          student.assessmentTeamId && users.length > 0
            ? users.find(({ id }) => id === student.assessmentTeamId)?.name
            : "",
        followupTeamId: student.followupTeamId,
        followUpTeam:
          student.followupTeamId && users.length > 0 ? users.find(({ id }) => id === student.followupTeamId)?.name : "",
        complianceTeamId: student.complianceTeamId,
        complianceTeam:
          student.complianceTeamId && users.length > 0
            ? users.find(({ id }) => id === student.complianceTeamId)?.name
            : "",
        admin: student.adminName,
        agentId: student.agentId,
        agent: student.agentCompanyName,
        referralName: student.referralName,
        eventName: student.eventName,
        eventId: student.eventId,
        bdm:
          student.businessDevAppUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === student.businessDevAppUserDetailsId)?.name
            : "",
        refbdm:
          student.referralBDMAppUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === student.referralBDMAppUserDetailsId)?.name
            : "",
        profile: student.profileComplete,
        status: student.status,
        statusName: STUDENTSTATUS.find(({ id }) => id == student.status)?.type,
        branchOfficeId: student.branchOfficeId,
        branch: student.branchOfficeName,
        sourceId: student.source,
        source: student.source && student.source > 0 ? STUDENTSOURCES.find(({ id }) => id == student.source)?.type : "",
        campaign: student.campaign,
        passport: student.passportNumber,
        gender: student.gender > 0 ? GENDER.find(({ id }) => id == student.gender)?.type : "",
        phone: student.phoneNumber,
        dateOfBirth: student.dateOfBirth == null ? "" : new Date(student.dateOfBirth).toISOString().split("T")[0],
        regionalManagerId: student.regionalManagerId,
        regionalManager:
          student.regionalManagerId && users.length > 0
            ? users.find(({ id }) => id === student.regionalManagerId)?.name
            : "",
        countryManagerId: student.countryManagerId,
        countryManager:
          student.countryManagerId && users.length > 0
            ? users.find(({ id }) => id === student.countryManagerId)?.name
            : "",
        branchManagerId: student.branchManagerId,
        branchManager:
          student.branchManagerId && users.length > 0
            ? users.find(({ id }) => id === student.branchManagerId)?.name
            : "",
        virtualTeamId: student.virtualTeamId,
        virtualTeam:
          student.virtualTeamId && users.length > 0 ? users.find(({ id }) => id === student.virtualTeamId)?.name : "",
        preferenceStudyLevel:
          student.preferenceStudyLevelId && student.preferenceStudyLevelId > 0
            ? STUDY_LEVEL_GROUPS.find(({ id }) => id == student.preferenceStudyLevelId)?.type
            : "",
        isLogin: student.isLoginCreated,
        studentLeadId: student.studentLeadId,
        referralId: student.referralId,
        leadactivityName:
          student.leadActivity && student.leadActivity > 0
            ? StudentLead_Activities.find(({ id }) => id === student.leadActivity)?.name
            : "",
        createdOn: student.createdOn == null ? "" : new Date(student.createdOn).toISOString().split("T")[0],
        statusupdatedon:
          student.statusUpdatedOn == null ? "" : new Date(student.statusUpdatedOn).toISOString().split("T")[0],
        firstAppLogin: student.firstAppLogin,
        updatedOn:
          student.updatedOn == null || student.updatedOn === "0001-01-01T00:00:00"
            ? ""
            : new Date(student.updatedOn).toISOString().split("T")[0],
      }))
    },
    [users],
  )

  const getData = useCallback(
    async (currentFilters) => {
      setLoading(true)
      try {
        const params = getParams(currentFilters)
        console.log("API request params:", Object.fromEntries(params))
        const studentListPage = await Agent.Students?.list(params)
        const { pagination, data } = studentListPage
        setStudents(getRows(data))
        setTotalPages(pagination?.totalPages)
        setTotalRows(pagination?.totalItems)
      } catch (error) {
        console.error("Error fetching student data:", error)
        setStudents([])
        setTotalPages(0)
        setTotalRows(0)
      } finally {
        setLoading(false)
      }
    },
    [getParams, getRows],
  )

  const handleNote = (row) => {
    setOpenAddNote(true)
    setStudentId(row.id)
  }

  const showEditOptions = (row) => {
    setStudent(row)
    if (users.length === 0) {
      loadUsers()
        .then(() => {
          if (agents.length === 0) {
            loadAgents()
              .then(() => setReady(true))
              .catch((error) => console.log(error))
          } else {
            setReady(true)
          }
        })
        .catch((error) => console.log(error))
    } else if (agents.length === 0) {
      loadAgents()
        .then(() => setReady(true))
        .catch((error) => console.log(error))
    } else {
      setReady(true)
    }
  }

  const updateStudentList = useCallback(
    (updatedStudent) => {
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === updatedStudent.id
            ? {
                ...student,
                ...updatedStudent,
                statusName: STUDENTSTATUS.find(({ id }) => id == updatedStudent.status)?.type,
                agent: updatedStudent.agent || student.agent,
                eventName: updatedStudent.eventId
                  ? events.find((event) => event.id === updatedStudent.eventId)?.eventName
                  : student.eventName,
                admin: updatedStudent.admin || student.admin,
                assessmentTeam: updatedStudent.assessment || student.assessmentTeam,
                followUpTeam: updatedStudent.followUp || student.followUpTeam,
                complianceTeam: updatedStudent.compliance || student.complianceTeam,
                branch: updatedStudent.branchOffice || student.branch,
                source: updatedStudent.source
                  ? STUDENTSOURCES.find(({ id }) => id == updatedStudent.source)?.type
                  : student.source,
                complianceStatusName: updatedStudent.complianceStatus
                  ? COMPLIANCE_STATUS.find(({ id }) => id === updatedStudent.complianceStatus)?.type
                  : student.complianceStatusName,
                regionalManager: updatedStudent.regionalManager || student.regionalManager,
                countryManager: updatedStudent.countryManager || student.countryManager,
                branchManager: updatedStudent.branchManager || student.branchManager,
                virtualTeam: updatedStudent.virtualTeam || student.virtualTeam,
                referralName: updatedStudent.ReferralId
                  ? referralList.find((ref) => ref.id === updatedStudent.ReferralId)?.firstName +
                    " " +
                    referralList.find((ref) => ref.id === updatedStudent.ReferralId)?.lastName
                  : student.referralName,
              }
            : student,
        ),
      )
    },
    [events, referralList,],
  )

  const actionSelectedUser = useCallback(
    (record) => {
      return new Promise((resolve, reject) => {
        const assignDto = {
          studentIds: selectionModel.length > 0 ? selectionModel : [student.id],
          ...record,
        }

        Agent.Students.assign(assignDto)
          .then(() => {
            if (bulkUpdate) {
              setReady(false)
              getData(filters) // Refresh the entire list for bulk updates
            } else {
              // Update the specific student in the list
              updateStudentList({ id: student.id, ...record })
            }
            setReady(false)
            setStudent(null)
            setSuccessMessage("Student updated successfully")
            resolve()
          })
          .catch((error) => {
            console.log(error)
            setStudent(null)
            setReady(false)
            reject(error)
          })
      })
    },
    [bulkUpdate, student, selectionModel, filters, getData, updateStudentList],
  )

  useEffect(() => {
    if (updatedStudent) {
      setStudents((prevStudents) =>
        prevStudents.map((s) => (s.id === updatedStudent.id ? { ...s, ...updatedStudent } : s)),
      )
      setUpdatedStudent(null)
    }
  }, [updatedStudent])

  const studentColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 180,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => (
        <MyTooltip title={params.value} color="primary">
          <Typography className="cursor-pointer font-bold">{params.value}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: "statusName",
      headerName: "Status",
      width: 160,
      hide: !isInternal,
    },
    {
      field: "residence",
      headerName: "Residence",
      hide: false,
      width: isInternal ? 150 : 180,
    },
    {
      field: "citizenship",
      headerName: "Citizenship",
      width: isInternal ? 100 : 200,
      hide: false,
    },
    {
      field: "applicationCount",
      headerName: "App",
      width: isInternal ? 100 : 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "agent",
      headerName: "Agent Name",
      width: 170,
      hide: !isInternal,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "referralName",
      headerName: "Referral Name",
      width: 170,
      hide: !isInternal,
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 220,
      hide: !isInternal,
    },
    {
      field: "preferenceStudyLevel",
      headerName: "Preference Study Level",
      width: 170,
      align: "center",
      headerAlign: "center",
      hide: true,
    },
    {
      field: "complianceStatusName",
      headerName: "Compliance Status",
      width: 240,
      hide: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "admin",
      headerName: "Team Leader",
      width: 180,
      hide: !isInternal,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "assessmentTeam",
      headerName: "Assessment",
      width: 180,
      hide: !isInternal,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },

    {
      field: "followUpTeam",
      headerName: "Follow Up",
      width: 180,
      hide: !isInternal,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "note",
      headerName: "Note",
      width: 90,
      hide: !isInternal,
      renderCell: (params) => (
        <IconButton disabled={!isInternal} onClick={() => handleNote(params.row)} size="large">
          <Description />
        </IconButton>
      ),
    },
    {
      field: "profile",
      headerName: "Profile Status",
      width: isInternal ? 140 : 200,
      align: "center",
      renderCell: (params) => (
        <strong>
          {params.value ? (
            <MyTooltip icon={<CheckCircleIcon className="text-green-400" />} title="Profile Complete">
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested />} title="Not Completed">
              <IconButton size="large">
                <NotInterested className="text-red-600" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: "campaign",
      headerName: "Campaign",
      hide: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      hide: true,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      width: 100,
      hide: true,
    },
    {
      field: "updatedOn",
      headerName: "Updated On",
      width: 120,
      hide: false,
    },
    {
      field: "passport",
      headerName: "Passport",
      hide: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      hide: true,
    },
    {
      field: "bdm",
      headerName: "Business Dev Manager",
      hide: true,
    },
    {
      field: "refbdm",
      headerName: "Referral BDM",
      hide: true,
    },
    {
      field: "eventName",
      headerName: "Event Name",
      width: 220,
      hide: false,
    },
    {
      field: "isLogin",
      headerName: "Crm Login",
      width: 100,
      hide: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          {params.value ? (
            <MyTooltip icon={<CheckCircleIcon className="text-green-500" />} title="Login Created">
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested className="text-gray-400" />} title="Login Not Created">
              <IconButton size="large">
                <NotInterested className="text-gray-400" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: "firstAppLogin",
      headerName: "App Login Status",
      width: 150,
      hide: !isInternal,
      align: "center",
      renderCell: (params) => (
        <strong>
          {params.value ? (
            <MyTooltip
              icon={<CheckCircleIcon className="text-blue-500" />}
              title={`First Login: ${new Date(params.value).toLocaleString()}`}
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-blue-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested className="text-gray-400" />} title="Not Logged In Yet">
              <IconButton size="large">
                <NotInterested className="text-gray-400" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: "source",
      headerName: "Lead Source",
      width: 130,
      hide: !isInternal,
    },
    {
      field: "studentLeadId",
      headerName: "Student Lead",
      width: 100,
      hide: true,
      align: "center",
      renderCell: (params) => (
        <strong>
          {params.value ? (
            <MyTooltip icon={<CheckCircleIcon className="bg-green-500" />} title="Converted From Lead">
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : null}
        </strong>
      ),
    },
    {
      field: "complianceTeam",
      headerName: "Compliance",
      width: 180,
      hide: true,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "countryManager",
      headerName: "Country Manager",
      width: 180,
      hide: true,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "branchManager",
      headerName: "Branch Manager",
      width: 180,
      hide: true,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "regionalManager",
      headerName: "Regional Manager",
      width: 180,
      hide: true,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "virtualTeam",
      headerName: "Uni. Representative",
      width: 180,
      hide: true,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "statusupdatedon",
      headerName: "Status Updated Date",
      width: 150,
      align: "center",
      hide: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 90,
      hide: !isInternal || bulkUpdate,
      renderCell: (params) => (
        <IconButton disabled={!isInternal} onClick={() => showEditOptions(params.row)} size="large">
          <EditIcon />
        </IconButton>
      ),
    },
  ]

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg">
      <StudentFilters
        filters={tempFilters}
        StudentFilters
        setFilters={setTempFilters}
        applyFilter={() => {
          setFilters(tempFilters)
          getData(tempFilters)
        }}
        resetFilter={() => {
          const resetFilters = {
            id: "",
            name: "",
            email: "",
            PhoneNumber: "",
            startDate: addMonths(new Date(), -1),
            endDate: new Date(),
            dateType: 1,
            branchOfficeId: 0,
            Status: 0,
            LeadActivity: 0,
            PartnerReferralId: 0,
            PartnerAgentId: 0,
            ComplianceStatus: 0,
            TeamLeader: 0,
            eventId: 0,
            AssessmentTeamId: 0,
            FollowupTeamId: 0,
            Source: 0,
            Channel: 0,
            PassportNumber: "",
            Origin: 0,
            StudentType: 0,
            CountryOfResidence: 0,
            PreferenceStudyLevelId: "",
            dateOfBirth: null,
            destination: 0,
          }
          setTempFilters(resetFilters)
          setFilters(resetFilters)
          getData(resetFilters)
        }}
        setSuccessMessage={setSuccessMessage}
        onFilterChange={setTempFilters}
        isInternal={isInternal}
        userContext={userContext}
        navigate={navigate}
        bulkUpdate={bulkUpdate}
        setBulkUpdate={setBulkUpdate}
        users={users}
        filteredTeamLeaders={filteredTeamLeaders}
        filteredFollowAndAssessmentTeams={filteredFollowAndAssessmentTeams}
        offices={offices}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        referralList={referralList}
        AgentList={AgentList}
        events={events}
        selectionModel={selectionModel}
        showEditOptions={showEditOptions}
        role={role}
        buttonClass="bg-sky-600 hover:bg-sky-700 text-white"
        resetButtonClass="bg-blue-500 hover:bg-blue-600 text-white"
        filterClass="bg-white border border-gray-300 rounded-md shadow-sm"
      />

      <div className="mt-4" style={{ height: 730, width: "100%" }}>
        <DataGridPro
          rows={students}
          columns={studentColumns}
          loading={loading}
          density="compact"
          rowHeight={70}
          pagination
          paginationMode="server"
          pageSize={pageSize}
          rowCount={totalRows}
          onPageChange={(newPage) => setPageNumber(newPage + 1)}
          page={pageNumber - 1}
          components={{
            Toolbar: () => CustomToolbar(permissions.CanDownloadData, isInternal),
          }}
          checkboxSelection={isInternal && bulkUpdate}
          onSelectionModelChange={setSelectionModel}
          selectionModel={selectionModel}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true
            if (params.field === "name") {
              window.open(`/students/${params.id}/dashboard`, "_blank")
            }
          }}
          sx={{
            "& .MuiDataGrid-root": {
              borderColor: "rgb(226, 232, 240)", // slate-200
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "rgb(14, 165, 233)", // sky-500
              color: "white",
              fontSize: "1rem",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell": {
              borderColor: "rgb(226, 232, 240)", // slate-200
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "rgb(255, 255, 255)", // white
              "&:nth-of-type(even)": {
                backgroundColor: "rgb(248, 250, 252)", // slate-50
              },
              "&:hover": {
                backgroundColor: "rgb(240, 249, 255)", // sky-50
              },
            },
            "& .MuiDataGrid-columnSeparator": {
              color: "rgb(226, 232, 240)", // slate-200
            },
            "& .MuiDataGrid-selectedRowCount": {
              color: "rgb(14, 165, 233)", // sky-500
            },
          }}
        />
      </div>

      {ready && (
        <StudentAssignment
          bulkUpdate={bulkUpdate}
          referralList={referralList}
          setReferralReload={() => getData(filters)}
          student={bulkUpdate ? null : student}
          users={users.filter((item) => !item.isDisabled)}
          events={events}
          eventId={student ? student.eventId : 0}
          agents={agents.map((item) => ({ ...item, name: item.companyName }))}
          cancel={() => setReady(false)}
          action={actionSelectedUser}
          role={role}
          offices={offices}
          studentName={student ? `${student.firstName} ${student.lastName}` : ""}
          updateStudentList={updateStudentList}
          setUpdatedStudent={setUpdatedStudent}
        />
      )}

      {openAddNote && (
        <StudentNotes open={openAddNote} cancel={() => setOpenAddNote(false)} studentId={studentId} toName={userName} />
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            maxWidth: "400px",
            backgroundColor: "rgb(22, 163, 74)", // green-600
            color: "white",
            fontSize: "1rem",
            alignItems: "center",
            "& .MuiAlert-icon": {
              color: "white",
              fontSize: "1.5rem",
            },
            "& .MuiAlert-message": {
              flex: 1,
            },
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  users: state.users,
  agents: state.agents,
  role: state.auth.role,
  permissions: state.auth.permissions,
  isInternal: state.auth.isInternal,
  agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  userName: state.auth.name,
})

const mapDispatchToProps = (dispatch) => ({
  loadUsers: () => dispatch(actions.loadUsers()),
  loadAgents: () => dispatch(actions.loadAgents()),
})

export default connect(mapStateToProps, mapDispatchToProps)(StudentList)

