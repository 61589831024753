import React from "react"
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, IconButton } from "@mui/material"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import FilterListIcon from "@mui/icons-material/FilterList"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import { STUDENTSTATUS, STUDENTSOURCES, COMPLIANCE_STATUS, STUDY_LEVEL_GROUPS } from "../../Util/Constants"
import { Countries } from "../../Util/Util"
import { getYear, getMonth } from "date-fns"
import { useNavigate } from "react-router-dom"

const range = (start, end, step = 1) => {
  const length = Math.floor((end - start) / step) + 1
  return Array(length)
    .fill()
    .map((_, idx) => start + idx * step)
}

const StudentFilters = ({
  filters,
  applyFilter,
  resetFilter,
  onFilterChange,
  isInternal,
  bulkUpdate,
  setBulkUpdate,
  filteredTeamLeaders,
  filteredFollowAndAssessmentTeams,
  offices,
  showFilters,
  setShowFilters,
  referralList,
  AgentList,
  events,
  selectionModel,
  showEditOptions,
  role,
}) => {
  const navigate = useNavigate()
  const years = range(1990, getYear(new Date()) + 1, 1)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const handleFilterChange = (field, value) => {
    onFilterChange({ ...filters, [field]: value })
  }

  const renderSelect = (label, value, onChange, options, defaultOption = "All") => (
    <FormControl fullWidth size="small" className="bg-white rounded-md shadow-sm">
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={(e) => onChange(e.target.value)} label={label}>
        <MenuItem value={0}>{defaultOption}</MenuItem>
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name || item.type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  const renderTextField = (label, value, onChange) => (
    <TextField
      fullWidth
      size="small"
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="bg-white rounded-md shadow-sm"
    />
  )

  const renderDatePicker = (label, value, onChange) => (
    <div className="w-full relative">
      <DatePicker
        selected={value ? new Date(value) : null}
        onChange={(date) => onChange(date)}
        isClearable
        dateFormat="dd/MM/yyyy"
        customInput={
          <TextField
            fullWidth
            size="small"
            label={label}
            className="bg-white rounded-md shadow-sm"
            inputProps={{ readOnly: true }}
          />
        }
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center justify-between px-2 py-2">
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className={`
                px-2 py-1 rounded-md text-sm font-semibold
                ${prevMonthButtonDisabled ? "text-gray-300 cursor-not-allowed" : "text-gray-700 hover:bg-gray-100"}
              `}
            >
              {"<"}
            </button>

            <div className="flex gap-2">
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(Number(value))}
                className="text-sm bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                className="text-sm bg-white border border-gray-200 rounded-md px-2 py-1 hover:border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className={`
                px-2 py-1 rounded-md text-sm font-semibold
                ${nextMonthButtonDisabled ? "text-gray-300 cursor-not-allowed" : "text-gray-700 hover:bg-gray-100"}
              `}
            >
              {">"}
            </button>
          </div>
        )}
      />
    </div>
  )

  const renderAutocomplete = (label, value, onChange, options, getOptionLabel, multiple = false) => (
    <Autocomplete
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel}
      value={multiple ? value : options.find((option) => option.id === value) || null}
      onChange={(event, newValue) => {
        if (multiple) {
          onChange(newValue.map((item) => item.id))
        } else {
          onChange(newValue ? newValue.id : 0)
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} size="small" />}
      fullWidth
      size="small"
      className="bg-white rounded-md shadow-sm"
    />
  )

  const handleRegisterClick = () => {
    if (isInternal) {
      navigate("/addleadform")
    } else if (role.isAgent) {
      navigate("/students/register")
    }
  }

  return (
    <div className="mb-8 bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Students</h2>
        <div className="flex items-center space-x-4">
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilters(!showFilters)}
            className="text-sky-600 border-sky-600 hover:bg-sky-50"
            size="medium"
          >
            {showFilters ? "Hide Filters" : "Show Filters"}
          </Button>
          {(isInternal || role.isAgent || role.isAgentStaff) && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleRegisterClick}
              className="bg-sky-600 hover:bg-sky-700 text-white font-bold"
              size="medium"
            >
              {isInternal ? "Add New Student" : "Register New Student"}
            </Button>
          )}
        </div>
      </div>

      {showFilters && (
        <div className="space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
            {renderTextField("Id", filters.id, (value) => handleFilterChange("id", value))}
            {renderTextField("Email", filters.email, (value) => handleFilterChange("email", value))}
            {renderTextField("Name", filters.name, (value) => handleFilterChange("name", value))}
            {renderTextField("Phone", filters.PhoneNumber, (value) => handleFilterChange("PhoneNumber", value))}
            {renderTextField("Passport", filters.PassportNumber, (value) =>
              handleFilterChange("PassportNumber", value),
            )}

            {isInternal && (
              <>
                {renderSelect(
                  "Branch",
                  filters.branchOfficeId,
                  (value) => handleFilterChange("branchOfficeId", value),
                  offices,
                )}
                {renderSelect("Status", filters.Status, (value) => handleFilterChange("Status", value), STUDENTSTATUS)}
                {renderAutocomplete(
                  "Residence",
                  filters.CountryOfResidence,
                  (value) => handleFilterChange("CountryOfResidence", value),
                  Countries.filter((item) => item.id !== 0),
                  (option) => option.name,
                )}
                {renderAutocomplete(
                  "Team Leader",
                  filters.TeamLeader,
                  (value) => handleFilterChange("TeamLeader", value),
                  filteredTeamLeaders,
                  (option) => option.name,
                )}
                {renderAutocomplete(
                  "Assessment",
                  filters.AssessmentTeamId,
                  (value) => handleFilterChange("AssessmentTeamId", value),
                  filteredFollowAndAssessmentTeams,
                  (option) => option.name,
                )}
                {renderAutocomplete(
                  "Follow-up",
                  filters.FollowupTeamId,
                  (value) => handleFilterChange("FollowupTeamId", value),
                  filteredFollowAndAssessmentTeams,
                  (option) => option.name,
                )}
                {renderSelect(
                  "Student Type",
                  filters.StudentType,
                  (value) => handleFilterChange("StudentType", value),
                  [
                    { id: 1, name: "Agent" },
                    { id: 2, name: "Direct" },
                    { id: 3, name: "Referral" },
                  ],
                )}
                {renderSelect(
                  "Compliance",
                  filters.ComplianceStatus,
                  (value) => handleFilterChange("ComplianceStatus", value),
                  COMPLIANCE_STATUS,
                )}
                {renderSelect("Source", filters.Source, (value) => handleFilterChange("Source", value), STUDENTSOURCES)}
                {renderSelect(
                  "Study Level",
                  filters.PreferenceStudyLevelId,
                  (value) => handleFilterChange("PreferenceStudyLevelId", value),
                  STUDY_LEVEL_GROUPS,
                )}
                {renderAutocomplete(
                  "Referral",
                  filters.PartnerReferralId,
                  (value) => handleFilterChange("PartnerReferralId", value),
                  referralList,
                  (option) => `${option.firstName} ${option.lastName}`,
                )}
                {renderAutocomplete(
                  "Agent",
                  filters.PartnerAgentId,
                  (value) => handleFilterChange("PartnerAgentId", value),
                  AgentList,
                  (option) => option.companyName,
                )}
                {renderAutocomplete(
                  "Event",
                  filters.eventId,
                  (value) => handleFilterChange("eventId", value),
                  events,
                  (option) => option.eventName,
                )}
         
              </>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
            <div className="w-full">
              {renderDatePicker("Start Date", filters.startDate, (date) => handleFilterChange("startDate", date))}
            </div>
            <div className="w-full">
              {renderDatePicker("End Date", filters.endDate, (date) => handleFilterChange("endDate", date))}
            </div>
            <div className="w-full">
              {renderSelect("Date Type", filters.dateType, (value) => handleFilterChange("dateType", value), [
                { id: 1, name: "Added" },
                { id: 2, name: "Modified" },
                { id: 3, name: "Status Changed" },
              ])}
            </div>

            {renderAutocomplete(
              "Destination",
              filters.destination,
              (value) => handleFilterChange("destination", value),
              Countries.filter((item) => item.id !== 0),
              (option) => option.name,
            )}
          </div>

          <div className="flex justify-end items-center space-x-4">
            <Button
              onClick={() => applyFilter(filters)}
              variant="contained"
              size="medium"
              className="bg-sky-600 hover:bg-sky-700 text-white font-bold"
            >
              Apply Filters
            </Button>
            <Button
              onClick={resetFilter}
              variant="outlined"
              size="medium"
              className="border-blue-500 text-blue-500 hover:bg-blue-50 font-bold"
            >
              Reset Filters
            </Button>
            {isInternal && (
              <Button
                onClick={() => setBulkUpdate(!bulkUpdate)}
                variant="outlined"
                size="medium"
                className="border-sky-600 text-sky-600 hover:bg-sky-50 font-bold"
              >
                {bulkUpdate ? "Disable Bulk" : "Enable Bulk"}
              </Button>
            )}
            {bulkUpdate && selectionModel.length > 0 && (
              <IconButton
                onClick={() => showEditOptions(null)}
                size="medium"
                className="bg-gray-100 hover:bg-gray-200 text-gray-700"
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default StudentFilters

